import React from "react"

import { kebabCase } from "lodash"
import { Link as Button } from "gatsby"
import { Link } from "./Link"
import { Date } from "./Date"
import { Youtube } from "./Youtube"

const PostCard = ({ post }) => {
  return (
    <div className="columns my-4">
      <article
        className={`my-2 blog-list-item box notification ${
          post.frontmatter.featuredpost ? "is-featured" : ""
        }`}>
        <header>
          <p className="post-meta">
            <Link className="title has-text-primary is-size-4" to={post.fields.slug}>
              {post.frontmatter.title}
            </Link>
            <span className="subtitle is-size-5 is-block mt-2">
              <Date>{post.frontmatter.date}</Date>
            </span>
          </p>
        </header>
        <p>
          <i>{(post.frontmatter.authors || []).join(", ")}</i>
        </p>

        {post.frontmatter.youtubeURL && <Youtube size="small" src={post.frontmatter.youtubeURL} />}

        <div className="mt-2 is-flex is-flex-direction-column">
          <Button className="button is-align-self-flex-end" to={post.fields.slug}>
            Leia mais →
          </Button>
        </div>
      </article>
    </div>
  )
}

const TagLink = ({ tag, currentTag }) => {
  const tagKey = kebabCase(tag.fieldValue)

  const classes = tagKey === kebabCase(currentTag) && "active-tag"

  return (
    <li className="tag-link">
      <Link className={classes} to={`/tags/${tagKey}/`}>
        {tag.fieldValue}
      </Link>
      <span> ({tag.totalCount})</span>
    </li>
  )
}

const Posts = ({ tags, posts, currentTag, children }) => {
  tags = tags || []
  posts = posts || []

  return (
    <div className="columns is-multiline">
      <div className="is-parent column is-8">
        {children}
        {posts.map(({ node: post }) => (
          <PostCard post={post} key={post.id} />
        ))}
      </div>
      <div className="is-parent column is-4">
        <h2 className="subtitle">Tags</h2>
        <ul className="list-style-none">
          {tags.map((tag) => (
            <TagLink tag={tag} key={tag.fieldValue} currentTag={currentTag} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Posts
