import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout, { Section } from "../components/Layout"
import Posts from "../components/Posts"
import PageImage from "../components/PageUnsplashImage"

const TagRoute = (props) => {
  const posts = props.data.forCurrentTag.edges
  const tag = props.pageContext.tag
  const title = props.data.site.siteMetadata.title
  const totalCount = props.data.forCurrentTag.totalCount
  const postsCopy = totalCount === 1 ? "postagem" : "postagens"
  const tagHeader = `${totalCount} ${postsCopy} com a TAG “${tag}”`
  const tags = props.data.forAllTags.tags

  return (
    <Layout>
      <PageImage
        src="/img/spirituality.jpg"
        credit={{
          author: "Mohamed Nohassi",
          href: "https://unsplash.com/@coopery?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText",
        }}>
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          {tag}
        </h1>
      </PageImage>
      <Section helmet={<Helmet title={`${tag} | ${title}`} />}>
        <h3 className="title is-size-5 is-bold-light">{tagHeader}</h3>
        <Posts posts={posts} tags={tags} currentTag={tag} />
      </Section>
    </Layout>
  )
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    forCurrentTag: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            authors
            date
          }
        }
      }
    }
    forAllTags: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "post-page" } } }
    ) {
      tags: group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
